
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import RestTable from '@/components/table/RestTable.vue';

export default defineComponent({
  name: 'SimCardType',
  components: { RestTable },
  setup() {
    const { https, mapper } = useInject();
    const tableOpts = {
      pageable: false,
      rowKey: 'code',
      search: {
        fetch: true,
        criteria: {
          cols: [{
            field: 'code',
            label: '编码'
          }]
        },
        remote: {
          action: https?.market.listSimCardType
        }
      },
      cols: [
        {
          field: 'code',
          label: '编码'
        },
        {
          field: 'name',
          label: '名称'
        },
        {
          field: 'backerNo',
          label: '品牌号',
          options: mapper?.device.backerNo
        },
        {
          field: 'sort',
          label: '序号'
        },
        {
          field: 'topAgentProfit',
          label: '一代返现金额'
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.common.enableStr
        },
      ],
      schema: {
        content: {
          cols: [
            {
              field: 'code',
              label: '编码',
              disabled: ['edit'],
              required: true
            },
            {
              field: 'name',
              label: '名称',
              required: true
            },
            {
              field: 'backerNo',
              label: '品牌号',
              type: 'select',
              options: mapper?.device.backerNo,
              required: true
            },
            {
              field: 'sort',
              label: '序号',
              type: 'number'
            },
            {
              field: 'topAgentProfit',
              label: '一代返现金额',
              type: 'number',
              scale: 2,
              required: true
            },
            {
              field: 'status',
              label: '状态',
              type: 'switch',
              options: mapper?.common.enableNum,
              required: true
            },
          ]
        }
      },
      builtinCmd: {
        create: { action: https?.market.createSimCardType },
        update: { action: https?.market.updateSimCardType },
        delete: { action: https?.market.deleteSimCardType },
      }
    };

    return { tableOpts };
  }
});
